import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './assets/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icons.css';
import './assets/css/pace.min.css';
import './assets/css/sidebar-menu.css';
import './assets/css/animate.css';
import './assets/css/app-style.css';
import './core/i18n/config.ts';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

const AuthenticatedRoutes = lazy(() => import('./routes/Authenticated.tsx'));
const UnAuthenticatedRoutes = lazy(() => import('./routes/UnAuthenticated.tsx'));

const SiteLayout = lazy(() => import('./core/Layout/index.tsx'));
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
var user = localStorage.getItem('user')
// const isAuthenticated = () => Object.keys(JSON.parse(user)).length !== 0;
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {user !==null ? 
          <SiteLayout>
            <AuthenticatedRoutes /> 
          </SiteLayout>
          : 
          <UnAuthenticatedRoutes />
        }
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
